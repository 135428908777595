import React from "react"

import "./index.scss"

const Heading = ({ title }) => (
  <div className="row">
    <div className="col-12 text-center mainheading">
      <h2>{title}</h2>
    </div>
  </div>
)

export default Heading
